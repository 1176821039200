body {
  background-color: #191f37;
  color: white;
}
.App, .quiz-content, .flag-quiz, .home-container, .flag-container {
  text-align: center;
}
.quiz-container{
  background-color: goldenrod;
}
.welcome, .quiz-start{
  font-size: x-large;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}
nav {
  display: flex;
  align-items: center;
  justify-content: center;
}
nav ul li {
  display: inline;
  margin: 0 10px;
  justify-items: center;
  list-style: none;

}

nav ul li a {
  text-decoration: none;
  color: #20d810;
}

nav ul li a:hover {
  text-decoration: underline;
}
.flag-list{
  background-color: bisque;
  color: black;
}

.flag-list img {
  width: 70px;
  height: auto;
}
.flag-choice img{
  width: 80px;
  height: auto;
}

.flag-item {
  display: inline-block;
  margin: 3px;
  padding: 5px;
  
}

.country-name {
  margin-bottom: 20px;
}

.score-timer {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
.countries {
  word-wrap: break-word;
}
header {
  background-color: blanchedalmond;
  min-height: 15vh;
}
header li {
  list-style: none;
  display: inline;
}
.footer{
  background-color: bisque;
  margin-top: 200px;
  max-height: 100vh;
}
footer li {
  padding: 10px;
}